import React from "react";

function DeleteModalIllustration() {
  return (
    <svg
      width="500"
      height="200"
      data-name="Layer 1"
      viewBox="0 0 920.304 515.087"
    >
      <path
        fill="#e6e6e6"
        d="M333.527 705.582h-140.6s-2.85-41.8 14.012-42.275 14.962 18.525 36.1-7.6 46.787-24.7 50.112-9.262-6.412 27.787 11.4 23.987 43.463 6.175 28.976 35.15z"
        data-name="Path 1"
        transform="translate(-139.848 -192.457)"
      ></path>
      <path
        fill="#fff"
        d="M260.854 705.592l-.475-.019c1.107-27.52 6.87-45.2 11.51-55.19 5.039-10.844 9.894-15.234 9.943-15.277l.316.355c-.048.043-4.846 4.39-9.844 15.16-4.615 9.944-10.347 27.554-11.45 54.971z"
        data-name="Path 2"
        transform="translate(-139.848 -192.457)"
      ></path>
      <path
        fill="#fff"
        d="M311.74 705.648l-.456-.133a73.682 73.682 0 0118.55-30.863l.32.352a73.183 73.183 0 00-18.414 30.644z"
        data-name="Path 3"
        transform="translate(-139.848 -192.457)"
      ></path>
      <path
        fill="#fff"
        d="M215.073 705.615l-.471-.065a51.732 51.732 0 00-4.653-30.238 41.633 41.633 0 00-7.34-10.716l.329-.343a42.155 42.155 0 017.44 10.848 52.206 52.206 0 014.695 30.514z"
        data-name="Path 4"
        transform="translate(-139.848 -192.457)"
      ></path>
      <path
        fill="#2f2e41"
        d="M352.608 706.217H177.763l-.04-.592c-.1-1.473-2.331-36.228 8.93-48.629a12.334 12.334 0 019.013-4.325c7.34-.209 11.953 2.541 15.674 4.747 7.013 4.16 11.645 6.91 28.49-13.91 18.247-22.553 37.936-27.406 49.254-24.708 7.313 1.744 12.353 6.671 13.828 13.52 1.4 6.485.952 12.578.594 17.475-.383 5.24-.685 9.376 1.467 11.252 1.8 1.565 5.38 1.707 11.28.448 12-2.56 28.749-.37 37.154 10.491 4.522 5.843 8.085 16.463-.623 33.88zm-173.652-1.27H351.82c6.489-13.166 6.692-24.288.58-32.183-7.71-9.963-23.887-12.585-35.882-10.026-6.411 1.368-10.23 1.142-12.381-.732-2.631-2.292-2.308-6.71-1.9-12.3.352-4.815.789-10.808-.57-17.115-1.367-6.35-6.062-10.926-12.88-12.55-10.957-2.615-30.1 2.176-47.971 24.27-17.534 21.671-22.817 18.54-30.126 14.2-3.767-2.235-8.043-4.768-14.99-4.57a11.109 11.109 0 00-8.108 3.908c-10.118 11.146-8.884 42.398-8.636 47.097z"
        data-name="Path 5"
        transform="translate(-139.848 -192.457)"
      ></path>
      <path
        fill="#3f3d56"
        d="M923.908 706.923H751.692l-.033-.965-8.223-235.18h188.727zm-170.284-2h168.352l8.117-232.145H745.506z"
        data-name="Path 8"
        transform="translate(-139.848 -192.457)"
      ></path>
      <g fill="#3f3d56" data-name="Group 1">
        <path
          d="M639.826 321.897H652.9250000000001V483.994H639.826z"
          data-name="Rectangle 17"
        ></path>
        <path
          d="M691.402 321.897H704.5010000000001V483.994H691.402z"
          data-name="Rectangle 18"
        ></path>
        <path
          d="M742.978 321.897H756.077V483.994H742.978z"
          data-name="Rectangle 19"
        ></path>
      </g>
      <path
        fill="#3f3d56"
        d="M901.75 347.382l-.846-.534L686.99 211.665l18.555-29.362.846.534L920.304 318.02zm-212-136.34l211.376 133.58 16.418-25.98-211.376-133.58z"
      ></path>
      <path
        fill="#3f3d56"
        d="M989.95 393.226a38.459 38.459 0 00-58.62 38.07l10.2 6.446a30.344 30.344 0 1128.98 18.321l10.2 6.446a38.459 38.459 0 009.249-69.283z"
        data-name="Path 10"
        transform="translate(-139.848 -192.457)"
      ></path>
      <path
        fill="#3f3d56"
        d="M0 513.087H909V515.087H0z"
        data-name="Rectangle 21"
      ></path>
      <g data-name="Group 6">
        <path
          fill="#feb8b8"
          d="M536.885 691.896H522.32l-6.932-56.174h21.5z"
          data-name="Path 111"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#2f2e41"
          d="M494.234 705.418h45.77v-17.684h-28.331a17.439 17.439 0 00-17.44 17.44z"
          data-name="Path 112"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#feb8b8"
          d="M531.766 604.851l10.046 10.545 45.452-33.727-14.826-15.563z"
          data-name="Path 113"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#2f2e41"
          d="M552.161 620.227l-19.549-20.512-12.803 12.202 31.582 33.138.177-.169a17.441 17.441 0 00.593-24.658z"
          data-name="Path 114"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#feb8b8"
          d="M430.396 450.953a11.946 11.946 0 015.715-17.4l57.179-145.727 22.288 13.345-63.518 139.8a12.01 12.01 0 01-21.664 9.982z"
          data-name="Path 115"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#feb8b8"
          d="M647.428 461.398a11.945 11.945 0 01-10.727-14.85L552.347 314.68l23.89-10.2 75.837 133.523a12.01 12.01 0 01-4.646 23.4z"
          data-name="Path 116"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#2f2e41"
          d="M493.853 436.361l14.93 221.913 35.683-3.148 7.346-163.722 19.94 70.314 43.028 3.148-17.031-139z"
          data-name="Path 117"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#2f2e41"
          d="M578.049 551.224l-6.3 10.495-44.073 30.434 31.484 16.792s60.869-33.583 55.622-44.078z"
          data-name="Path 118"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#ccc"
          d="M462.607 346.577l12.42-35a62.494 62.494 0 0132.333-35.668 89.427 89.427 0 0152.484-2.873l4.52 1.122a87.364 87.364 0 0133.128 16c7.654 6.034 14.54 13.674 15.153 21.892a.244.244 0 00.015.051c2.12 9.292 3.169 57.567 3.169 57.567h-18.7l2.958 65.067-.24-.47s-107.855 20.41-107.855 9.915v-67.168l-2.211-24.32z"
          data-name="Path 119"
          transform="translate(-139.848 -192.457)"
        ></path>
        <circle
          cx="423.432"
          cy="41.593"
          r="29.889"
          fill="#feb8b8"
          data-name="Ellipse 12"
        ></circle>
        <path
          fill="#2f2e41"
          d="M567.757 220.645l23.208.93c2.92-.009 6.108-.112 8.332-2 3.35-2.849 2.789-8.225.995-12.24-5-11.183-16.153-15.189-28.4-14.86s-25.08 4.48-31.675 14.8-8.377 23.352-5.893 35.344a38.534 38.534 0 0131.508-21.97z"
          data-name="Path 120"
          transform="translate(-139.848 -192.457)"
        ></path>
      </g>
      <g data-name="Group 4">
        <path
          fill="#ff8a8a"
          d="M705.571 513.001l-84.001-58.873a3.607 3.607 0 01-.882-5.014l65.931-94.073a3.607 3.607 0 015.015-.882l84.001 58.873a3.607 3.607 0 01.882 5.015l-65.93 94.07a3.607 3.607 0 01-5.016.884z"
          data-name="Path 81"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#fff"
          d="M724.462 449.12l-49.29-34.545a5.3 5.3 0 116.084-8.682l49.29 34.546a5.3 5.3 0 01-6.084 8.681z"
          data-name="Path 82"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#fff"
          d="M713.15 465.261l-49.29-34.545a5.3 5.3 0 116.083-8.682l49.291 34.546a5.3 5.3 0 01-6.084 8.681z"
          data-name="Path 83"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#fff"
          d="M701.716 481.576l-49.291-34.546a5.3 5.3 0 116.084-8.681l49.291 34.545a5.3 5.3 0 01-6.084 8.682z"
          data-name="Path 84"
          transform="translate(-139.848 -192.457)"
        ></path>
        <path
          fill="#fff"
          d="M724.324 417.19l-19.092-13.38a5.3 5.3 0 116.084-8.682l19.092 13.38a5.3 5.3 0 01-6.084 8.682z"
          data-name="Path 85"
          transform="translate(-139.848 -192.457)"
        ></path>
      </g>
    </svg>
  );
}

export default DeleteModalIllustration;
