export const expenseOptions = [
  "Please Select",
  "Groceries",
  "Online Shopping",
  "Fuel",
  "Bills",
  "Eating Out",
  "Savings",
  "Retail",
  "Gifts",
  "Other",
];
